<template>
<div class="opacity-page">
    <div class="header-filter" v-if="loaded">
        <div class="row-space-tbf search-row">
            <div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
            <div class="content">
                <div class="search-input">
                    <input type="text" :placeholder="$t('index-users.search-by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
                </div>
                <div class="actions-page mobile">
                    <button class="btn-tbf blue" @click="$router.push({name: 'salary-tickets-create'})">
                        <div class="icon">
                            <icon-plus />
                        </div>
                        <span v-if="$resize && $mq.above(600)" class="text">{{ $t('general.add') }}</span>
                    </button>
                   <div class="actions actions-dropdown">
						<div class="actions-page mobile">
							<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
								<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
								<span class="text">{{ $t('general.filters') }}</span>
								<div class="icon-filter"><icon-arrow /></div>
							</button>
							<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
								<div class="icon settings"><icon-settings /></div>
							</button>
						</div>
					</div>
                </div>
            </div>
            <div class="space-right"></div>
        </div>
		<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.below(1120)}]" v-if="extendFilters">
			<div class="space-left"></div>
			<div class="content" :class="[{'contract-25': $resize && $mq.above(1121)}]">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedUsers.length">{{ $t('filters.by_users') }}</span>
							<span class="text" v-else-if="selectedUsers.length == 1">{{ filterUsers.find(el => el.id == selectedUsers[0]).name }}</span>
							<span class="text" v-else>{{ selectedUsers.length }} {{ $t('filters.responsibles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')">
							</div>

							<template v-for="user in filteredUsers">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedUsers', user.id)" v-bind:class="{active: selectedUsers.includes(user.id)}" :key="'filter-user-' + user.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ user.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedDepartments.length">{{ $t('filters.by_departments') }}</span>
							<span class="text" v-else-if="selectedDepartments.length == 1">{{ filterDepartments.find(el => el.id == selectedDepartments[0]).name }}</span>
							<span class="text" v-else>{{ selectedDepartments.length }} {{ $t('filters.departments_selected') }}</span>

							<div class="icon-filter" v-if="!selectedDepartments.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedDepartments')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchDepartments" :placeholder="$t('filters.search_department')">
							</div>

							<template v-for="department in filteredDepartments">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedDepartments', department.id)" v-bind:class="{active: selectedDepartments.includes(department.id)}" :key="'filter-department-' + department.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ department.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last-but-one">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedWorkstations.length">{{ $t('filters.by_workstations') }}</span>
							<span class="text" v-else-if="selectedWorkstations.length == 1">{{ filterWorkstations.find(el => el.id == selectedWorkstations[0]).name }}</span>
							<span class="text" v-else>{{ selectedWorkstations.length }} {{ $t('filters.workstations_selected') }}</span>

							<div class="icon-filter" v-if="!selectedWorkstations.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedWorkstations')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkstations" :placeholder="$t('filters.search_workstations')">
							</div>

							<template v-for="workstation in filteredWorkstations">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedWorkstations', workstation.id)" v-bind:class="{active: selectedWorkstations.includes(workstation.id)}" :key="'filter-workstation-' + workstation.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ workstation.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
							<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
							<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
							</div>

							<template v-for="role in filteredRoles">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ role.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedContractType.length">{{ $t('filters.by_contract_type') }}</span>
							<span class="text" v-else-if="selectedContractType.length == 1">{{ filterContractsType.find(el => el.id == selectedContractType[0]).name }}</span>
							<span class="text" v-else>{{ selectedContractType.length }} {{ $t('filters.contract_type_selected') }}</span>

							<div class="icon-filter" v-if="!selectedContractType.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedContractType')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchContractsType" :placeholder="$t('filters.search_contract_type')">
							</div>

							<template v-for="contract in filteredContractsType">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedContractType', contract.id)" v-bind:class="{active: selectedContractType.includes(contract.id)}" :key="'filter-contract-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedContractPeriodType.length">{{ $t('filters.by_contract_period_type') }}</span>
							<span class="text" v-else-if="selectedContractPeriodType.length == 1">{{ filterContractPriodType.find(el => el.id == selectedContractPeriodType[0]).name }}</span>
							<span class="text" v-else>{{ selectedContractPeriodType.length }} {{ $t('filters.contract_period_type_selected') }}</span>

							<div class="icon-filter" v-if="!selectedContractPeriodType.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedContractPeriodType')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchContractPriodType" :placeholder="$t('filters.search_contract_period_type')">
							</div>

							<template v-for="contract_period in filteredContractPriodType">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedContractPeriodType', contract_period.id)" v-bind:class="{active: selectedContractPeriodType.includes(contract_period.id)}" :key="'filter-contract-period-' + contract_period.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract_period.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last-but-one">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedHoursPerDay.length">{{ $t('filters.by_hours_per_day') }}</span>
							<span class="text" v-else-if="selectedHoursPerDay.length == 1">{{ filterHoursPerDays.find(el => el.id == selectedHoursPerDay[0]).name }}</span>
							<span class="text" v-else>{{ selectedHoursPerDay.length }} {{ $t('filters.hours_per_day') }}</span>

							<div class="icon-filter" v-if="!selectedHoursPerDay.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedHoursPerDay')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchHoursPerDay" :placeholder="$t('filters.search_hours_per_day')">
							</div>

							<template v-for="hour_per_day in filteredHoursPerDays">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedHoursPerDay', hour_per_day.id)" v-bind:class="{active: selectedHoursPerDay.includes(hour_per_day.id)}" :key="'filter-hours-' + hour_per_day.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ hour_per_day.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedWorkConditionTypes.length">{{ $t('filters.by_work_condition_types') }}</span>
							<span class="text" v-else-if="selectedWorkConditionTypes.length == 1">{{ filterWorkConditionTypes.find(el => el.id == selectedWorkConditionTypes[0]).name }}</span>
							<span class="text" v-else>{{ selectedWorkConditionTypes.length }} {{ $t('filters.work_condition_types') }}</span>

							<div class="icon-filter" v-if="!selectedWorkConditionTypes.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedWorkConditionTypes')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkConditionTypes" :placeholder="$t('filters.search_work_condition_types')">
							</div>

							<template v-for="work_condition in filteredWorkConditionTypes">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedWorkConditionTypes', work_condition.id)" v-bind:class="{active: selectedWorkConditionTypes.includes(work_condition.id)}" :key="'filter-work-condition-' + work_condition.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ work_condition.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown third-row">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedTicketTypes.length">{{ $t('filters.by_ticket_types') }}</span>
							<span class="text" v-else-if="selectedTicketTypes.length == 1">{{ filterTicketTypes.find(el => el.id == selectedTicketTypes[0]).name }}</span>
							<span class="text" v-else>{{ selectedTicketTypes.length }} {{ $t('filters.ticket_selected') }}</span>

							<div class="icon-filter" v-if="!selectedTicketTypes.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedTicketTypes')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchTicketTypes" :placeholder="$t('filters.search_ticket_types')">
							</div>

							<template v-for="contract in filteredTicketTypes">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedTicketTypes', contract.id)" v-bind:class="{active: selectedTicketTypes.includes(contract.id)}" :key="'filter-ticket-type-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					
					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(1121)"/>
						<div v-else-if="$resize && $mq.below(1120)">{{$t('filters.clear_all')}}</div>
					</button>
				</div>
			<div class="space-right"></div>
		</div>
    </div>
    <loader-header-list v-else/>

	<template v-if="loadedList">
	<div class="data-list list-maintenance list-tab-show-page" v-if="entries.length">
		<div class="row-space-tbf header-list">
			<div class="space-left"></div>
			<div class="content">
				<div class="column-filter column-user" v-if="$resize && $mq.above(501)"></div>
				<div class="column-filter column-name">
					<div class="text">{{ $t('general.name') }}</div>
				</div>
				<div v-if="$resize && $mq.above(1660)" class="column-filter column-roles">
					<div class="text">{{ $t('index-users.roles') }}</div>
				</div>
				<div v-if="$resize && $mq.above(1500)" class="column-filter column-departments">
					<div class="text">{{ $t('index-users.departments') }}</div>
				</div>
				<div v-if="$resize && ($mq.above(501) && (!$mq.between([781, 850]) && !$mq.between([1025, 1300])))" class="column-filter column-type">
					<div class="text">{{ $t('tickets.ticket_type') }}</div>
				</div>
				<div v-if="$resize && ($mq.above(600) && (!$mq.between([781, 935]) && !$mq.between([1025, 1190])))" class="column-filter column-number-tickets">
					<div class="text">{{ $t('salary.show-contract.number_tickets') }}</div>
				</div>
				<div v-if="$resize && ($mq.above(760) && (!$mq.between([781, 1025]) && !$mq.between([1025, 1100])))" class="column-filter column-returned">
					<div class="text">{{ $t('tickets.returned') }}</div>
				</div>
				<div v-if="$resize && ($mq.above(685) && (!$mq.between([781, 1025]) && !$mq.between([1025, 1100])))" class="column-filter column-nominal-value">
					<div class="text">{{ $t('tickets.nominal_value') }}</div>
				</div>
				<div v-if="$resize && ($mq.above(501) && !$mq.between([1025, 1000]))" class="column-filter column-total-amount">
					<div class="text">{{ $t('salary.show-contract.total_amount') }}</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>
		<template v-for="entry in entries">
			<div class="row-space-tbf row-list-item" :key="'tickets' + entry.id">
				<div class="space-left"></div>
				<div class="content">
					<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
					<div class="column-avatar column-user" :class="[{hide: $resize && !$mq.above(501)}]">
						<img :src="entry.avatar" v-if="entry.avatar">
						<div class="user-circle" v-else>
							<icon-user />
						</div>
					</div>
					<div class="column-name-desc column-name link">
						<div class="name">{{ entry.first_name + ' ' + entry.last_name }}</div>
						<div v-if="entry.marca" class="description">{{$t('salary.contract-data.mark') + ': ' + entry.marca}}</div>
					</div>
					<div v-if="$resize && $mq.above(1660)" class="column-simple-text column-roles">
						<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
							<div class="text roles">
								<span class="first" v-if="entry.roles.length == 1">{{ entry.roles[0].name }}</span>
								<span v-else-if="entry.roles.length > 1">{{`${entry.roles[0].name} (+${(entry.roles.length - 1)})`}}</span>
							</div>
							<template slot="popover">
								<div class="simple-text"><span v-for="(role, index) in entry.roles">{{index < entry.roles.length - 1 ? role.name + ', ' : role.name}}</span></div>
							</template>
						</v-popover>
					</div>
					<div v-if="$resize && $mq.above(1500)" class="column-simple-text column-departments">
						<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
							<div class="text departments">
								<span class="first" v-if="entry.departments.length == 1">{{ entry.departments[0].name }}</span>
								<span v-else-if="entry.departments.length > 1">{{`${entry.departments[0].name} (+${(entry.departments.length - 1)})`}}</span>
							</div>
							<template slot="popover">
								<div class="simple-text"><span v-for="(department, index) in entry.departments">{{index < entry.departments.length - 1 ? department.name + ', ' : department.name}}</span></div>
							</template>
						</v-popover>
					</div>
					<div v-if="$resize && ($mq.above(501) && (!$mq.between([781, 850]) && !$mq.between([1025, 1300])))" class="column-simple-text column-type">
						<div class="text">{{entry.ticket_type_name}}</div>
					</div>
					<div v-if="$resize && ($mq.above(600) && (!$mq.between([781, 935]) && !$mq.between([1025, 1190])))" class="column-simple-text column-number-tickets">
						<div class="text">{{entry.number}}</div>
					</div>
					<div v-if="$resize && ($mq.above(760) && (!$mq.between([781, 1025]) && !$mq.between([1025, 1100])))" class="column-simple-text column-returned">
						<div class="text">{{entry.returned}}</div>
					</div>
					<div v-if="$resize && ($mq.above(685) && (!$mq.between([781, 1025]) && !$mq.between([1025, 1100])))" class="column-simple-text column-nominal-value">
						<div class="text">{{entry.nominal_value}}</div>
					</div>
					<div v-if="$resize && ($mq.above(501) && !$mq.between([1025, 1000]))" class="column-simple-text column-total-amount">
						<div class="text">{{entry.total_amount}}</div>
					</div>
					<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
						<div class="dropdown dropdown-edit">
							<div class="button-edit" :ref="'dropdownEditRef'+entry.id" :id="'dropdownEdit'+entry.id" data-toggle="dropdown">
								<icon-edit-mobile />
							</div>
							<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ entry.id " :aria-labelledby="'dropdownEdit'+entry.id">
								<div class="dropdown-item" @click="editTickets()"><div class="simple-text">{{$t('general.edit')}}</div></div>
								<div class="dropdown-item" @click="showModal('delete', {type: 'tickets', from: 'index_tickets', model: entry})"><div class="simple-text">{{$t('general.delete')}}</div></div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right">
					<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
						<div class="button-edit" :ref="'dropdownEditRef'+entry.id" :id="'dropdownEdit'+entry.id" data-toggle="dropdown">
							<icon-edit />
						</div>
						<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ entry.id " :aria-labelledby="'dropdownEdit'+entry.id">
							<div class="dropdown-item" @click="editTickets()"><div class="simple-text">{{$t('general.edit')}}</div></div>
							<div class="dropdown-item" @click="showModal('delete', {type: 'tickets', from: 'index_tickets', model: entry})"><div class="simple-text">{{$t('general.delete')}}</div></div>
						</div>
					</div>
				</div>
			</div>
		</template>
		</div>
		<div class="row-space-tbf list-empty" v-else>
			<div class="space-left"></div>
			<div class="content full">
				<div class="title">{{ $t('empty.title')}}</div>
			</div>
			<div class="space-right"></div>
		</div>
	</template>
	<loader-items-list-2 v-else/>

	<infinite-loading :identifier="infiniteId" @infinite="infiniteTicketsHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
</div>
</template>

<script>
	import IconArrow from '../../../Icons/Arrow'
	import IconTrash from '../../../Icons/Trash'
	import IconClose from '../../../Icons/Close'
	import IconSettings from '../../../Icons/Settings'
    import IconSearch from '../../../Icons/Search'
	import IconUser from '../../../Icons/User'
	import IconEdit from '../../../Icons/EditDots'
    import IconPlus from '../../../Icons/Plus'
	import IconEditMobile from '../../../Icons/Edit'
	import LoaderItemsList2 from '../../../PagesLoaders/ItemsList2'
    import LoaderHeaderList from '../../../PagesLoaders/HeaderList'
	import InfiniteLoading from 'vue-infinite-loading'

	export default {
		components: {
			IconArrow,
			IconTrash,
			IconClose,
			IconSettings,
            IconSearch,
			IconUser,
			IconEdit,
            IconPlus,
			IconEditMobile,
			LoaderItemsList2,
            LoaderHeaderList,
            InfiniteLoading
		},
		data() {
			return {
				loaded: false,
            	loadedList: false,
            	search_filter: '',
				page: 0,
				infiniteId: 1,
				queryFiltersCount: 0,
            	extendFilters: false,

				filterUsers: [],
            	selectedUsers: [],
            	searchUsers: '',

				filterRoles: [],
            	selectedRoles: [],
            	searchRoles: '',

				filterDepartments: [],
            	selectedDepartments: [],
            	searchDepartments: '',

				filterWorkstations: [],
            	selectedWorkstations: [],
            	searchWorkstations: '',

				filterContractsType: [],
            	selectedContractType: [],
            	searchContractsType: '',

				filterContractPriodType: [],
            	selectedContractPeriodType: [],
            	searchContractPriodType: '',

				filterHoursPerDays: [],
				selectedHoursPerDay: [],
				searchHoursPerDay: '',

				filterWorkConditionTypes: [],
				selectedWorkConditionTypes: [],
				searchWorkConditionTypes: '',

				filterTicketTypes: [],
				selectedTicketTypes: [],
				searchTicketTypes: '',

				entries: [],

				payroll_date: {},
			}
		},
		async mounted(){
			if(this.$route.params.data) {
				this.payroll_date = this.$route.params.data
			}
			
			this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)

			await this.getFilters()
        	await this.checkQueryFilters()

            setTimeout(() => {
				var title = this.$t('salary-navbar.tickets');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshSalaryIndex', (date) => {
				this.payroll_date.month = date.month
				this.payroll_date.year = date.year
				
				this.searchFilterFunct()
			})

			this.$root.$on('refreshTicketsIndex', async() => {
        		await this.checkQueryFilters()
				await this.searchFilterFunct()
			})
		},
		beforeDestroy() {
			this.$root.$off('refreshSalaryIndex')
        },
		watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
		computed: {
			filteredUsers(){
				return getByKeywordFilter(this.filterUsers, this.searchUsers)
			},
			filteredDepartments(){
				return getByKeywordFilter(this.filterDepartments, this.searchDepartments)
			},
			filteredWorkstations(){
				return getByKeywordFilter(this.filterWorkstations, this.searchWorkstations)
			},
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
			filteredContractsType(){
				return getByKeywordFilter(this.filterContractsType, this.searchContractsType)
			},
			filteredContractPriodType(){
				return getByKeywordFilter(this.filterContractPriodType, this.searchContractPriodType)
			},
			filteredHoursPerDays() {
				return getByKeywordFilter(this.filterHoursPerDays, this.searchHoursPerDay)
			},
			filteredWorkConditionTypes() {
				return getByKeywordFilter(this.filterWorkConditionTypes, this.searchWorkConditionTypes)
			},
			filteredTicketTypes() {
				return getByKeywordFilter(this.filterTicketTypes, this.searchTicketTypes)
			}
        },

		methods: {
            async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users: true, departments: true, workstations: true, roles: true, contract_types: true, contract_period_types: true, hours_per_day: true, work_condition_types: true, ticket_types: true}})
				.then(({data}) => {
					this.filterUsers = data.data.users
					this.filterRoles = data.data.roles
					this.filterDepartments = data.data.departments
					this.filterWorkstations = data.data.workstations
					this.filterContractsType = data.data.contract_types
					this.filterContractPriodType = data.data.contract_period_types
					this.filterHoursPerDays = data.data.hours_per_day.map(el => {return {
						id: el.hours_per_day, name: el.hours_per_day.toString()
					}})
					this.filterWorkConditionTypes = data.data.work_condition_types
					this.filterTicketTypes = data.data.ticket_types
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => { this.loaded = true })
			},
			infiniteTicketsHandler($state){
				if(this.payroll_date.month && this.payroll_date.year) {
					var paramsCall = { view_more: this.page * 20, month: this.payroll_date.month, year: this.payroll_date.year}
					this.queryObject(paramsCall)

					axios.get(this.$auth.user().instance.id + '/payroll-tickets', {params: paramsCall})
					.then(({data}) => {
						if(data.data.length){
							this.page++
							this.entries.push(...data.data)
							setTimeout(() => { $state.loaded() }, 200)
								if(data.data.length < 20){
									$state.complete()
								}
						}else{
							$state.complete()
						}
						this.loadedList = true

					}).catch(error => {
						if(error.response) {
							if(error.response.status == 500) {
								alert(this.$t('error.500'))
							}
						}
					}).finally(() => { 
						setTimeout(() => {
							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}, 0)
					})
				}

        	},

			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }

					if(key == 'users'){ paramsCall.user_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'departments'){ paramsCall.department_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'workstations'){ paramsCall.workstation_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'roles'){ paramsCall.role_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_type_id'){ paramsCall.contract_type_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_period_type_id'){ paramsCall.contract_type_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'hours_per_days'){ paramsCall.hours_per_days = dataQuery[key].split(',').map(Number) }
					if(key == 'work_condition_type_id'){ paramsCall.work_condition_type_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'ticket_type_id'){ paramsCall.ticket_type_ids = dataQuery[key].split(',').map(Number) }
				});
			},
			selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
					
				} else {
					if(this[filter].includes(parseInt(value))){
					
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
					
				}

				this.addFilterToRouteQuery(filter)
        	},
			addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedUsers' || filterName == 'all'){ dataQuery['users'] = this.selectedUsers.toString(); }
				if(filterName == 'selectedWorkstations' || filterName == 'all'){ dataQuery['workstations'] = this.selectedWorkstations.toString(); }
				if(filterName == 'selectedDepartments' || filterName == 'all'){ dataQuery['departments'] = this.selectedDepartments.toString(); }
				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }
				if(filterName == 'selectedContractType' || filterName == 'all'){ dataQuery['contract_type_id'] = this.selectedContractType.toString(); }
				if(filterName == 'selectedContractPeriodType' || filterName == 'all'){ dataQuery['contract_period_type_id'] = this.selectedContractPeriodType.toString(); }
				if(filterName == 'selectedHoursPerDay' || filterName == 'all'){ dataQuery['hours_per_days'] = this.selectedHoursPerDay.toString(); }
				if(filterName == 'selectedWorkConditionTypes' || filterName == 'all'){ dataQuery['work_condition_type_id'] = this.selectedWorkConditionTypes.toString(); }
				if(filterName == 'selectedTicketTypes' || filterName == 'all'){ dataQuery['ticket_type_id'] = this.selectedTicketTypes.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedUsers = []
					this.selectedContractType = []
					this.selectedWorkstations = []
					this.selectedDepartments = []
					this.selectedRoles = []
					this.selectedContractPeriodType = []
					this.selectedHoursPerDay = []
					this.selectedWorkConditionTypes = []
					this.selectedTicketTypes = []
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }

					if(key == 'users'){ this.selectedUsers = dataQuery[key].split(',').map(Number) }
					if(key == 'workstations'){ this.selectedWorkstations = dataQuery[key].split(',').map(Number) }
					if(key == 'departments'){ this.selectedDepartments = dataQuery[key].split(',').map(Number) }
					if(key == 'roles'){ this.selectedRoles = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_type_id'){ this.selectedContractType = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_period_type_id'){ this.selectedContractPeriodType = dataQuery[key].split(',').map(Number) }
					if(key == 'hours_per_days'){ this.selectedHoursPerDay = dataQuery[key].split(',').map(Number) }
					if(key == 'work_condition_type_id'){ this.selectedWorkConditionTypes = dataQuery[key].split(',').map(Number) }
					if(key == 'ticket_type_id'){ this.selectedTicketTypes = dataQuery[key].split(',').map(Number) }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			editTickets() {
                this.$router.push({name: 'salary-tickets-create'})
            },
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			searchFilterFunct() {
				this.loadedList = false
				this.page = 0
				this.entries = []
				this.infiniteId += 1
			},
		}
	}
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
    .list-maintenance {
        .row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
        .column-name{
			flex: 1 1 auto;
			&.link{
				cursor: pointer;
			}
		}
		.column-user{
			flex: 0 0 60px;
			display: flex;
			justify-content: flex-start;
		}
        .column-type
        {
            flex: 0 0 120px;
        }
        .column-nominal-value,
        .column-total-amount{
			flex: 0 0 110px;
		}
        .column-number-tickets,
        .column-returned{
            flex: 0 0 80px;
        }
		.column-roles{
			flex: 0 0 180px;
			.roles{
				display: flex;
				padding-right: 15px;
				.word{
					padding-right: 10px;
				}
				.number{
					padding-right: 10px;
					text-align: center;
					min-width: 57px;
					line-height: 16px;
					padding-bottom: 3px;
					margin: auto;
				}
			}
		}
		.column-departments{
			flex: 0 0 180px;
			.departments{
				display: flex;
				padding-right: 15px;
				.word{
					padding-right: 10px;
				}
				.number{
					padding-right: 10px;
					text-align: center;
					min-width: 57px;
					line-height: 16px;
					padding-bottom: 3px;
					margin: auto;
				}
			}
		}
    }
    .text{
        &.tickets {
            .first {
                font-size: 14px;
                line-height: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
</style>